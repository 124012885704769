import { IPaginationResponse } from 'dtos/pagination.dto';
import { IProduct } from 'interfaces/product.interface';
import { useEffect, useState } from 'react';
import useSWR from 'swr';

export default function useProducts(queryStringify: string) {
  const [products, setProducts] = useState<IProduct[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const {
    data: productData,
    error,
    isValidating,
  } = useSWR<IPaginationResponse<IProduct>>(
    queryStringify
      ? `${process.env.NEXT_PUBLIC_API_URL}/v1/public/products?${queryStringify}`
      : null
  );

  useEffect(() => {
    if (productData) {
      setProducts(productData.docs);

      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [productData]);

  return {
    productData,
    products: products,
    isProductsLoading: !error && !productData,
    isValidating,
  };
}
