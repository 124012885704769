import {
  AspectRatio,
  Box,
  Container,
  Heading,
  SimpleGrid,
  Skeleton,
} from '@chakra-ui/react';
import HomeSectionBanner from 'components/home/HomeBannerSection';
import HomeFeaturedCategoriesSection from 'components/home/HomeFeaturedCategoriesSection';
import HomeProductCategorySection from 'components/home/HomeProductCategorySection';
import HomeProductList from 'components/home/HomeProductList';
import HomeSectionHeader from 'components/home/HomeSectionHeader';
import HomeSingleBanner from 'components/home/HomeSingleBanner';
import useCatchError from 'hooks/useCatchError';
import {
  IHomeBanner,
  IHomeFeatureCategory,
  IHomeSingleBanner,
  IHomeSlider,
  IProductCategorySection,
} from 'interfaces/home.interface';
import { IPage } from 'interfaces/page.interface';
import RootLayout from 'layouts/RootLayout';
import { isEmpty } from 'lodash';
import { GetStaticProps } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Head from 'next/head';
import Image from 'next/image';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { PageRepository } from 'respository/page.service';
import { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import useSWR from 'swr';
import { NextPageWithLayout } from './_app';

type HomePageProps = {
  pageProp: any;
};

import useProductCategories from 'hooks/useProductCategories';
import useProductMainCategories from 'hooks/useProductMainCategories';
import Script from 'next/script';
import 'swiper/css/pagination';

const HomePage: NextPageWithLayout<HomePageProps> = ({
  pageProp,
}: HomePageProps) => {
  const { t } = useTranslation(['home', 'common']);

  // use catch error
  const catchError = useCatchError();

  // use product main categories
  const { productMainCategories } = useProductMainCategories();

  // use product categories
  const { productCategories } = useProductCategories();

  // use router
  const router = useRouter();

  // init page
  const [page, setPage] = useState<IPage>(pageProp);

  // init main slider
  const [mainSlider, setMainSlider] = useState<IHomeSlider[]>([]);

  // init first banner
  const [firstBanner, setFirstBanner] = useState<IHomeSingleBanner | null>(
    null
  );

  // init featuredCategories
  const [featuredCategories, setFeaturedCategories] = useState<
    IHomeFeatureCategory[]
  >([]);

  // init spacial banner
  const [spacialBanner, setSpacialBanner] = useState<IHomeSingleBanner | null>(
    null
  );

  // init product spacial price query string
  const [productSpacialPriceQueryString, setProductSpacialPriceQueryString] =
    useState<string>('');

  // init product best seller ids
  const [productBestSellerQueryString, setProductBestSellerQueryString] =
    useState<string>('');

  // init product new arrival query string
  const [productNewArrivalQueryString, setProductNewArrivalQueryString] =
    useState<string>('');

  // init topBottomBanner
  const [topBottomBanners, setTopBottomBanners] = useState<IHomeBanner[]>([]);

  const [catalogBannersTop, setCatalogBannersTop] = useState<IHomeBanner[]>([]);

  // init catalogBanners
  const [catalogBanners, setCatalogBanners] = useState<IHomeBanner[]>([]);

  const [catalogBannersBottom, setCatalogBannersBottom] = useState<
    IHomeBanner[]
  >([]);

  // init topBottomBanner
  const [bottomBanners, setBottomBanners] = useState<IHomeBanner[]>([]);

  // init product category section
  const [productCategorySections, setProductCategorySections] = useState<
    IProductCategorySection[]
  >([]);

  // get paage
  const { data: pageHomeData } = useSWR<any>(
    `${process.env.NEXT_PUBLIC_API_URL}/v1/pages/permalink/home`
  );

  useEffect(() => {
    const getPage = async () => {
      try {
        // get page
        const page = await PageRepository.findOneByPermalink('home');

        // log page
        console.log('page', page);
        setPage(page);
      } catch (error) {
        catchError(error);
      }
    };

    getPage();
  }, []);

  useEffect(() => {
    if (pageHomeData) {
      setPage(pageHomeData);
    }
  }, [pageHomeData]);

  useEffect(() => {
    if (page) {
      if (page.customFields) {
        // ********** Main slider **********
        const mainSlider = page.customFields.find(
          (field) => field.key === 'mainSlider'
        );

        if (mainSlider && !isEmpty(mainSlider)) {
          const mainSliderValue = JSON.parse(mainSlider.value);

          // log mainSlider value
          console.log('mainSliderValue', mainSliderValue);

          setMainSlider(mainSliderValue);
        }

        // ********** First banner **********
        const firstBanner = page.customFields.find(
          (field) => field.key === 'firstBanner'
        );

        // if first banner
        if (firstBanner && !isEmpty(firstBanner)) {
          const firstBannerValue = JSON.parse(firstBanner.value);

          // log first banner value
          console.log('firstBannerValue', firstBannerValue);

          setFirstBanner(firstBannerValue);
        }

        // ********** Featured categories **********
        const featuredCategories = page.customFields.find(
          (field) => field.key === 'featuredCategories'
        );

        // if featured categories
        if (featuredCategories && !isEmpty(featuredCategories)) {
          const featuredCategoriesValue = JSON.parse(featuredCategories.value);

          // log featured categories value
          console.log('featuredCategoriesValue', featuredCategoriesValue);

          setFeaturedCategories(featuredCategoriesValue);
        }

        // ********** Spacial banner **********
        const spacialBanner = page.customFields.find(
          (field) => field.key === 'spacialBanner'
        );

        // if spacial banner
        if (spacialBanner && !isEmpty(spacialBanner)) {
          const spacialBannerValue = JSON.parse(spacialBanner.value);

          // log spacial banner value
          console.log('spacialBannerValue', spacialBannerValue);

          setSpacialBanner(spacialBannerValue);
        }

        // ********** Best seller products **********
        const bestSellerProducts = page.customFields.find(
          (field) => field.key === 'bestSellerProducts'
        );

        if (bestSellerProducts && !isEmpty(bestSellerProducts)) {
          const bestSellerProductsValue = JSON.parse(bestSellerProducts.value);

          // log best seller products value
          console.log('bestSellerProductsValue', bestSellerProductsValue);

          setProductBestSellerQueryString(
            queryString.stringify({
              ids: bestSellerProductsValue.join(','),
              limit: 100,
              include: 'productBadge',
            })
          );
        }

        // ********** Product spacial price **********
        const spacialPriceProducts = page.customFields.find(
          (field) => field.key === 'spacialProducts'
        );

        if (spacialPriceProducts && !isEmpty(spacialPriceProducts)) {
          const spacialPriceProductsValue = JSON.parse(
            spacialPriceProducts.value
          );

          // log product spacial price value
          console.log('productSpacialPriceValue', spacialPriceProductsValue);

          setProductSpacialPriceQueryString(
            queryString.stringify({
              ids: spacialPriceProductsValue.join(','),
              limit: 100,
              include: 'productBadge',
            })
          );
        }

        // ********** Product new arrival **********
        const newArrivalProducts = page.customFields.find(
          (field) => field.key === 'newArrivalProducts'
        );

        if (newArrivalProducts && !isEmpty(newArrivalProducts)) {
          const newArrivalProductsValue = JSON.parse(newArrivalProducts.value);

          // log product new arrival value
          console.log('newArrivalProductsValue', newArrivalProductsValue);

          setProductNewArrivalQueryString(
            queryString.stringify({
              ids: newArrivalProductsValue.join(','),
              limit: 100,
              include: 'productBadge',
            })
          );
        }

        // ********** Product category section **********
        const productCategorySection = page.customFields.find(
          (field) => field.key === 'productCategorySection'
        );

        if (productCategorySection && !isEmpty(productCategorySection)) {
          const productCategorySectionValue: IProductCategorySection[] =
            JSON.parse(productCategorySection.value);

          console.log(
            'productCategorySectionValue',
            productCategorySectionValue
          );

          setProductCategorySections(productCategorySectionValue);
        }

        // ********** Top bottom banner **********
        const topBottomBanners = page.customFields.find(
          (field) => field.key === 'topBottomBanners'
        );

        if (topBottomBanners && !isEmpty(topBottomBanners)) {
          const topBottomBannersValue = JSON.parse(topBottomBanners.value);

          // log top bottom banner value
          console.log('topBottomBannersValue', topBottomBannersValue);

          setTopBottomBanners(topBottomBannersValue);
        }

        // ********** Catalog banners top **********
        const catalogBannersTop = page.customFields.find(
          (field) => field.key === 'catalogBannersTop'
        );

        // log catalogBannersTop
        console.log('catalogBannersTop', catalogBannersTop);

        if (catalogBannersTop && !isEmpty(catalogBannersTop)) {
          const catalogBannersTopValue = JSON.parse(catalogBannersTop.value);

          // log catalogBannersTopValue
          console.log('catalogBannersTopValue', catalogBannersTopValue);

          setCatalogBannersTop(catalogBannersTopValue);
        }

        // ********** Catalog banners **********
        const catalogBanners = page.customFields.find(
          (field) => field.key === 'catalogs'
        );

        if (catalogBanners && !isEmpty(catalogBanners)) {
          const catalogBannersValue = JSON.parse(catalogBanners.value);

          // log catalog banners value
          console.log('catalogBannersValue', catalogBannersValue);

          setCatalogBanners(catalogBannersValue);
        }

        // ********** Catalog banners bottom **********
        const catalogBannersBottom = page.customFields.find(
          (field) => field.key === 'catalogBannersBottom'
        );

        if (catalogBannersBottom && !isEmpty(catalogBannersBottom)) {
          const catalogBannersBottomValue = JSON.parse(
            catalogBannersBottom.value
          );

          // log catalog banners bottom value
          console.log('catalogBannersBottomValue', catalogBannersBottomValue);

          setCatalogBannersBottom(catalogBannersBottomValue);
        }

        // ********** Bottom banner **********
        const bottomBanners = page.customFields.find(
          (field) => field.key === 'bottomBanners'
        );

        if (bottomBanners && !isEmpty(bottomBanners)) {
          const bottomBannersValue = JSON.parse(bottomBanners.value);

          // log bottom banner value
          console.log('bottomBannersValue', bottomBannersValue);

          setBottomBanners(bottomBannersValue);
        }
      }
    }
  }, [page]);

  return (
    <>
      <Head>
        <title>แลมป์ตั้น | หลอดไฟและอุปกรณ์ไฟฟ้า</title>
        <meta
          property="og:image"
          content="https://lamptan-co-th.oss-ap-southeast-7.aliyuncs.com//home/main-sliders/01-mb-home-lighting-banner-1125x455px_th.jpeg"
        />
      </Head>
      <Script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-CTV7JPNFX7"
      ></Script>
      <Script id="google-analytics">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', 'G-CTV7JPNFX7');
        `}
      </Script>
      <Box
        bg={{
          base: '#eee',
          lg: '#fff',
        }}
        pb={5}
        overflow="hidden"
      >
        <Skeleton isLoaded={mainSlider && mainSlider.length > 0}>
          {mainSlider && mainSlider.length > 0 ? (
            <Box maxWidth="1920px" margin="0 auto">
              <Swiper
                spaceBetween={0}
                slidesPerView={1}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                pagination={{ clickable: true }}
                modules={[Autoplay, Pagination]}
                onSwiper={(swiper) => console.log(swiper)}
              >
                {mainSlider.map((slider, index) => (
                  <SwiperSlide
                    key={index}
                    onClick={(e) => {
                      console.log('slider.link', slider.link);
                      if (slider.link) {
                        // log slider.link
                        router.push(slider.link);
                      }

                      e.preventDefault();
                    }}
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    <Box className={`swiper-slide-${index}`}>
                      <Image
                        src={
                          slider.mediaDesktop
                            ? `${process.env.NEXT_PUBLIC_FILE_URL}/${slider.mediaDesktop.name}`
                            : '/images/empty-image.png'
                        }
                        alt={slider.mediaDesktop?.name ?? ''}
                        width={1920}
                        height={520}
                        quality={90}
                        style={{
                          width: '100%',
                          height: 'auto',
                        }}
                      ></Image>
                    </Box>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
          ) : (
            <AspectRatio ratio={32 / 9}>
              <Box></Box>
            </AspectRatio>
          )}
        </Skeleton>

        {firstBanner && (
          <Box maxWidth="1920px" margin="0 auto">
            {firstBanner.mediaDesktop || firstBanner.mediaMobile ? (
              <Box
                mb={{
                  base: 0,
                  lg: 10,
                }}
              >
                <NextLink href={firstBanner.link ?? ''}>
                  <HomeSingleBanner
                    banner={firstBanner}
                    desktopWidth={1920}
                    desktopHeight={520}
                    mobileWidth={500}
                    mobileHeight={150}
                  ></HomeSingleBanner>
                </NextLink>
              </Box>
            ) : (
              <></>
            )}
          </Box>
        )}

        {featuredCategories && featuredCategories.length > 0 && (
          <Box
            py={{
              base: 4,
              lg: 0,
            }}
            bg="white"
            mb={{
              base: 5,
              lg: 10,
            }}
          >
            <Container maxWidth="900px">
              <HomeFeaturedCategoriesSection
                featuredCategories={featuredCategories}
              ></HomeFeaturedCategoriesSection>
            </Container>
          </Box>
        )}

        {spacialBanner && (
          <Box
            mb={{
              base: 5,
              lg: 10,
            }}
          >
            <Container maxW="900px">
              {spacialBanner.mediaDesktop || spacialBanner.mediaMobile ? (
                <Box>
                  <NextLink href={spacialBanner.link ?? ''}>
                    <HomeSingleBanner
                      banner={spacialBanner}
                      desktopWidth={1920}
                      desktopHeight={520}
                      mobileWidth={500}
                      mobileHeight={150}
                    ></HomeSingleBanner>
                  </NextLink>
                </Box>
              ) : (
                <></>
              )}
            </Container>
          </Box>
        )}

        <Box
          py={{
            base: 4,
            lg: 0,
          }}
          bg="white"
          mb={{
            base: 5,
            lg: 10,
          }}
        >
          <Container maxW="900px">
            <HomeSectionHeader
              title={t('home.specialPromotion')}
              viewAllLink="/products?type=spacial-price"
            ></HomeSectionHeader>
            <HomeProductList
              query={productSpacialPriceQueryString}
            ></HomeProductList>
          </Container>
        </Box>

        <Box
          py={{
            base: 4,
            lg: 0,
          }}
          bg="white"
          mb={{
            base: 5,
            lg: 10,
          }}
        >
          <Container maxW="900px">
            <HomeSectionHeader
              title={t('home.bestSeller')}
              viewAllLink="/products?type=best-seller"
            ></HomeSectionHeader>
            <HomeProductList
              query={productBestSellerQueryString}
            ></HomeProductList>
          </Container>
        </Box>

        <Box
          py={{
            base: 4,
            lg: 0,
          }}
          bg="white"
          mb={{
            base: 5,
            lg: 10,
          }}
        >
          <Container maxW="900px">
            <HomeSectionHeader
              title={t('home.newArrival')}
              viewAllLink="/products?type=new-arrival"
            ></HomeSectionHeader>
            <HomeProductList
              query={
                productNewArrivalQueryString
                  ? productNewArrivalQueryString
                  : queryString.stringify({
                      isNewArrival: true,
                      limit: 100,
                    })
              }
            ></HomeProductList>
          </Container>
        </Box>

        {productCategorySections.length > 0 ? (
          <Box>
            {productCategorySections.map((section, index) => (
              <HomeProductCategorySection
                section={section}
                hideVerticalOnMobile
                key={index}
              />
            ))}
          </Box>
        ) : (
          <Box my={10}>
            <Container maxW="900px">
              <Skeleton mb={6}>
                <Box height="180px"></Box>
              </Skeleton>
              <SimpleGrid columns={5} spacing={6}>
                <Skeleton>
                  <Box height="100px"></Box>
                </Skeleton>
                <Skeleton>
                  <Box height="100px"></Box>
                </Skeleton>
                <Skeleton>
                  <Box height="100px"></Box>
                </Skeleton>
                <Skeleton>
                  <Box height="100px"></Box>
                </Skeleton>
                <Skeleton>
                  <Box height="100px"></Box>
                </Skeleton>
              </SimpleGrid>
            </Container>
          </Box>
        )}

        <Box
          py={{
            base: 4,
            lg: 0,
          }}
          bg="white"
          mb={{
            base: 5,
            lg: 10,
          }}
        >
          <Container
            maxW="900px"
            mb={4}
            display={{
              md: 'none',
            }}
          >
            <SimpleGrid columns={2} spacing={4}>
              {productCategorySections
                .filter((section) => section.layout === 'vertical')
                .map((section, index) => (
                  <NextLink
                    key={index}
                    href={`/product-categories/${
                      productMainCategories.find(
                        (category) => category.id === section.category
                      )?.permalink ?? ''
                    }/${
                      productCategories.find(
                        (category) => category.id === section.category
                      )?.permalink ?? ''
                    }`}
                  >
                    <Image
                      key={index}
                      src={`${process.env.NEXT_PUBLIC_FILE_URL}/${
                        section!.mediaDesktop!.name
                      }`}
                      alt={section!.mediaDesktop!.name}
                      quality={90}
                      width={400}
                      height={400}
                    ></Image>
                  </NextLink>
                ))}
            </SimpleGrid>
          </Container>

          {topBottomBanners && topBottomBanners.length > 0 && (
            <Box>
              <Container maxWidth="900px">
                <HomeSectionBanner
                  bannerItems={topBottomBanners}
                  columns={{
                    base: 2,
                    md: 3,
                  }}
                  spacing={{
                    base: 4,
                    md: 4,
                    lg: 6,
                  }}
                ></HomeSectionBanner>
              </Container>
            </Box>
          )}
        </Box>

        <Box
          py={{
            base: 4,
            lg: 0,
          }}
          bg="white"
          mb={{
            base: 0,
            md: 0,
            lg: 16,
          }}
        >
          {catalogBanners && catalogBanners.length > 0 && (
            <Box
              mb={{
                base: 5,
                md: 8,
                lg: 14,
              }}
            >
              <Container maxWidth="900px">
                <Heading
                  textTransform="uppercase"
                  fontSize={{
                    base: 'xl',
                    md: '3xl',
                    lg: '4xl',
                  }}
                  fontWeight="400"
                  color="primary.500"
                  mb={4}
                >
                  Lamptan Catalogue
                </Heading>

                {catalogBannersTop && catalogBannersTop.length > 0 && (
                  <Box mb={6}>
                    {catalogBannersTop.map((banner, index) => (
                      <Box key={index} mb={6}>
                        {banner.link ? (
                          <NextLink
                            href={banner.link ?? ''}
                            target={banner.target ?? '_self'}
                          >
                            <Image
                              src={
                                banner.media
                                  ? `${process.env.NEXT_PUBLIC_FILE_URL}/${banner.media.name}`
                                  : '/image-icon/empty-image.png'
                              }
                              alt=""
                              width={1920}
                              height={520}
                              style={{
                                width: '100%',
                                height: 'auto',
                              }}
                            ></Image>
                          </NextLink>
                        ) : (
                          <>
                            <Image
                              src={`${process.env.NEXT_PUBLIC_FILE_URL}/${banner.media.name}`}
                              alt=""
                              width={1920}
                              height={520}
                            ></Image>
                          </>
                        )}
                      </Box>
                    ))}
                  </Box>
                )}

                <HomeSectionBanner
                  bannerItems={catalogBanners}
                  columns={{
                    base: 3,
                  }}
                  spacing={{
                    base: 3,
                    md: 6,
                  }}
                  linkTarget="_blank"
                ></HomeSectionBanner>

                {catalogBannersBottom && catalogBannersBottom.length > 0 && (
                  <Box mb={6} mt={6}>
                    {catalogBannersBottom.map((banner, index) => (
                      <Box key={index} mb={6}>
                        {banner.link ? (
                          <NextLink
                            href={banner.link ?? ''}
                            target={banner.target ?? '_self'}
                          >
                            <Image
                              src={
                                banner.media
                                  ? `${process.env.NEXT_PUBLIC_FILE_URL}/${banner.media.name}`
                                  : '/image-icon/empty-image.png'
                              }
                              alt=""
                              width={1920}
                              height={520}
                              style={{
                                width: '100%',
                                height: 'auto',
                              }}
                            ></Image>
                          </NextLink>
                        ) : (
                          <>
                            <Image
                              src={`${process.env.NEXT_PUBLIC_FILE_URL}/${banner.media.name}`}
                              alt=""
                              width={1920}
                              height={520}
                            ></Image>
                          </>
                        )}
                      </Box>
                    ))}
                  </Box>
                )}
              </Container>
            </Box>
          )}

          {bottomBanners && bottomBanners.length > 0 && (
            <Box>
              <Container maxWidth="900px">
                <HomeSectionBanner
                  bannerItems={bottomBanners}
                  columns={{
                    base: 3,
                    md: 3,
                  }}
                  spacing={{
                    base: 3,
                    md: 6,
                  }}
                ></HomeSectionBanner>
              </Container>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

HomePage.getLayout = (page) => <RootLayout>{page}</RootLayout>;

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const _locale = locale || 'th';

  try {
    // get product
    const response = await fetch(
      `${process.env.API_URL}/v1/pages/permalink/home`
    );

    const page = await response.json();

    return {
      props: {
        pageProp: page,
        ...(await serverSideTranslations(_locale, ['home', 'common'])),
      },
      revalidate: 10,
    };
  } catch (error: any) {
    // log error.response.data
    console.log(error.response);

    throw new Error(`Failed to fetch posts, received status`);
  }
};

export default HomePage;
