import { Box } from '@chakra-ui/react';
import ProductListSwiper from 'components/products/ProductListSwiper';
import useProducts from 'hooks/useProducts';
import React from 'react';

type HomeProductListProps = {
  query: string;
};

function HomeProductList({ query }: HomeProductListProps) {
  const { products } = useProducts(query);

  // log query
  console.log('query', query);

  // log products
  console.log('products, HomeProductList', products);

  return (
    <Box>
      <ProductListSwiper
        products={products}
        breakpoints={{
          300: {
            slidesPerView: 2.5,
            spaceBetween: 10,
            slidesPerGroup: 2,
          },
          768: {
            slidesPerView: 4.5,
            spaceBetween: 20,
            slidesPerGroup: 4,
          },
          992: {
            slidesPerView: 5,
            spaceBetween: 30,
            slidesPerGroup: 5,
          },
        }}
      ></ProductListSwiper>
    </Box>
  );
}

export default HomeProductList;
