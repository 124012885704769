import { Box, Button, Heading, HStack, Link } from '@chakra-ui/react';
import React from 'react';
import NextLink from 'next/link';
import { useTranslation } from 'next-i18next';

type HomeSectionHeaderProps = {
  title: string;
  viewAllLink: string;
};

function HomeSectionHeader({ title, viewAllLink }: HomeSectionHeaderProps) {
  // use i18n
  const { t } = useTranslation(['home']);

  return (
    <HStack justifyContent="space-between" mb={1}>
      <Heading
        fontSize={{
          base: '2xl',
          md: '3xl',
        }}
        color="primary.500"
        fontWeight={400}
        textTransform="uppercase"
      >
        <NextLink href={viewAllLink} passHref legacyBehavior>
          <Link>{title}</Link>
        </NextLink>
      </Heading>
      <Box>
        <NextLink href={viewAllLink} passHref legacyBehavior>
          <Link fontSize="xl" textTransform="uppercase">
            {t('home.more')}
          </Link>
        </NextLink>
      </Box>
    </HStack>
  );
}

export default HomeSectionHeader;
